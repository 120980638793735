import React from 'react';
import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import {IWifiAnalyzerHistoryController} from './controller';
import {observer} from 'mobx-react';
import {SimpleLabel} from '@techsee/techsee-ui-common/lib/forms/labels/simple';
import {SecondaryButton} from '@techsee/techsee-ui-common/lib/forms/buttons/secondary';
import {Table} from '@techsee/techsee-ui-common/lib/table';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';
import {WifiAnalyzer, WifiAnalyzerMode} from '../../components/wifi-analyzer/component';
import {
    Spinner,
    SpinnerBackgroundColors,
    SpinnerColors,
    SpinnerSizes,
    SpinnerTypeOptions
} from '@techsee/techsee-ui-common/lib/spinner';
import {isEmpty} from 'lodash';

import './style.scss';

export interface IWifiAnalyzerView {
    controller: IWifiAnalyzerHistoryController;
}

@observer
export class WifiAnalyzerView extends TechseeBaseComponent<IWifiAnalyzerView> {
    renderInternal(): JSX.Element {
        const {controller} = this.props;
        const {
            translate,
            selectSession,
            goToInvitePage,
            prevPage,
            nextPage,
            currentPage,
            totalPages,
            isNextAvailable,
            isPrevAvailable,
            wifiAnalyzerList,
            startDate,
            endDate,
            getDateFormattedByAcc,
            wifiAnalyzerController,
            platformNameFormatted,
            getFormattedScanType
        } = controller;

        return (
            <div className='vj-history-state use-common-styles dashboard-main-container'>
                <div className='vj-history-head'>
                    {goToInvitePage && (
                        <div className='go-back' onClick={goToInvitePage}>
                            <Icon className='go-back-icon' iconName={'arrow-back'} />
                            <div className='go-back-text'>{translate('REACT.INTERACTION.SUMMARY.VJ.LIST.GO.BACK')}</div>
                        </div>
                    )}
                    <SimpleLabel className='title'>{translate('REACT.WIFI_ANALYZER.HISTORY_LIST_TITLE')}</SimpleLabel>
                    {startDate && endDate && (
                        <SimpleLabel>
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.FROM_TILL', {
                                from: getDateFormattedByAcc(startDate, false),
                                till: getDateFormattedByAcc(endDate, false)
                            })}
                        </SimpleLabel>
                    )}
                </div>
                <div className='vj-history-table'>
                    <Table bordered condensed hover responsive>
                        <thead>
                            <tr className='table-head'>
                                <th>{translate('REACT.WIFI_ANALYZER.HISTORY_DATE')}</th>
                                <th>{translate('REACT.CONNECTIVITY.VIEW.USERNAME')}</th>
                                <th>{translate('REACT.WIFI_ANALYZER.HISTORY_CUSTOMER_ID')}</th>
                                <th>{translate('REACT.WIFI_ANALYZER.HISTORY_SESSION_ID')}</th>
                                <th>{translate('REACT.WIFI_ANALYZER.HISTORY_DEVICE_MODEL')}</th>
                                <th>{translate('REACT.WIFI_ANALYZER.HISTORY_DEVICE_OS')}</th>
                                <th>{translate('REACT.WIFI_ANALYZER.HISTORY_SCAN_TYPE')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {wifiAnalyzerList.map((session: any, index: number) => (
                                <tr key={index} onClick={() => selectSession(session)}>
                                    <td>{getDateFormattedByAcc(session.createdOn, true)}</td>
                                    <td>{session.userName}</td>
                                    <td>{session.customerId}</td>
                                    <td>{session.roomId}</td>
                                    <td>{session.deviceModel}</td>
                                    <td>{platformNameFormatted(session.platformType)}</td>
                                    <td>{getFormattedScanType(session)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {isEmpty(wifiAnalyzerList) && (
                        <div className='wifi-spinner-wrapper'>
                            <Spinner
                                spinnerType={SpinnerTypeOptions.CIRCULAR}
                                sizeName={SpinnerSizes.Large}
                                backgroundColorName={SpinnerBackgroundColors.Transparent}
                                colorName={SpinnerColors.LightBlue}
                            />
                        </div>
                    )}
                </div>
                <div className='vj-history-table-pagination'>
                    <div className='prev-button'>
                        <SecondaryButton onClick={prevPage} disabled={!isPrevAvailable}>
                            <Icon iconName={'arrow-back'} />
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.PREVIOUS')}
                        </SecondaryButton>
                    </div>
                    <div className='page-of-pages'>
                        <SimpleLabel>
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.PAGE_OF_PAGES', {
                                page: currentPage,
                                pages: totalPages
                            })}
                        </SimpleLabel>
                    </div>
                    <div className='next-button'>
                        <SecondaryButton onClick={nextPage} disabled={!isNextAvailable}>
                            {translate('REACT.INTERACTION.SUMMARY.VJ.LIST.NEXT')}
                            <Icon iconName={'arrow-next'} />
                        </SecondaryButton>
                    </div>
                </div>
                <WifiAnalyzer ctrl={wifiAnalyzerController} mode={WifiAnalyzerMode.History} />
            </div>
        );
    }
}
