'use strict';
/* eslint-disable complexity,no-empty-function,no-nested-ternary */

import '@babel/polyfill';

// Load external libraries (JS and CSS)
import vendorModule from './vendor/vendor.module.js';

import ElementQueries from 'css-element-queries/src/ElementQueries';

ElementQueries.listen();

// Load app modules
import componentsModule from './components/components.module.js';
import directivesModule from './directives/directives.module.js';
import filtersModule from './filters/filters.module.js';
import servicesModule from './services/services.module.js';
import constantsModule from './constants/constants.module.js';
import statesModule from './states/states.module.js';

// Sentry
import './error-reporting';

//New code Bootstrap
import {getRootStore, reactAppBootstrap} from './_react_/app.bootstrap';

import {DASHBOARD_APP_TRACER, getAppTracer} from './app.tracer';

// App assets
import 'styles/main.scss';
import {
    enableTracerByName,
    registerTracerOutput,
    traceToConsoleOutputFunction
} from '@techsee/techsee-common/lib/core/Tracer';
import {TECHSEE_MEDIA_TRACER} from '@techsee/techsee-media-service/lib/MediaUtils/MediaTracer';
import {ssoCurrentUser, handleOAuthCallback} from './_react_/states/helper/general.helper';
import {TsUrlUtilsService} from './services/ts-url-utils/ts-url-utils.service';
import {PromiseUtilsService} from '@techsee/techsee-client-services/lib/services/PromiseUtilsService';
import {UrlUtilsService} from '@techsee/techsee-client-services/lib/services/UrlUtilsService';
import {createUniqueUserName} from '@techsee/techsee-common/lib/utils';
import {TsEnvironmentDetect} from '@techsee/techsee-common/lib/helpers/ts-environment-detect';

const trace = getAppTracer('app.module');
let environmentDetect;

function _unknownRouteHandler($state, $location, tsUrlUtils, auth, db, observe, user, alreadyLoggedOut) {
    const {getAuthToken, loginWithOneTimeToken, logoutWithoutSSO, clearAuthToken} = auth;

    const oAuthCode = UrlUtilsService.extractQueryParamValueFromUrl(window.location.search, 'code');
    const oAuthError = UrlUtilsService.extractQueryParamValueFromUrl(window.location.search, 'error');

    if (oAuthCode || oAuthError) {
        return handleOAuthCallback(oAuthCode, oAuthError, db, $state);
    }

    return ssoCurrentUser(tsUrlUtils, db.User.find, getAuthToken, loginWithOneTimeToken, $location.$$search.ott)
        .then((response) => {
            if (response && response.embeddedDashboardFailure) {
                trace.error(
                    'App.module failed - ssoCurrentUser result: ' +
                        JSON.stringify({
                            isEmbeddedhboard: true
                        }),
                    response.embeddedDashboardFailureError
                );

                return $state.go('embeddedDashboardGeneralError');
            }

            if (observe === 'guest') {
                const {r, p, c, is, roomId, g, roomCode, audio, observe, emb, mini, ott, tablet, sw} =
                    $location.$$search;

                return $state.go('login', {
                    p,
                    r,
                    c,
                    is,
                    roomId,
                    g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    ott,
                    tablet,
                    sw
                });
            }

            const {userName, accountId} = response;
            const userId = response._id;
            const ottRedeemed = response.wasRedeemed;
            const logoutUser = tsUrlUtils.getParamValue('logout') === 'true';
            const sandboxUserName = createUniqueUserName({shouldCreate: true}, user, accountId);
            const isExpectedUserLoggedIn = user === userName || sandboxUserName === userName;

            if (!alreadyLoggedOut && ((user && userName && !isExpectedUserLoggedIn) || (logoutUser && !ottRedeemed))) {
                logoutWithoutSSO(accountId, userId).finally(() =>
                    _unknownRouteHandler($state, $location, tsUrlUtils, auth, db, observe, user, true)
                );

                return;
            }

            // we get here when a previous token is placed on localstorage, but the login in backend no longer is valid
            if (user && !userName && !alreadyLoggedOut && !ottRedeemed) {
                clearAuthToken();

                _unknownRouteHandler($state, $location, tsUrlUtils, auth, db, observe, user, true);

                return;
            }

            if (!response.redirect) {
                const {
                    r,
                    p,
                    c,
                    is,
                    roomId,
                    g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    authId,
                    ott,
                    tablet,
                    sw,
                    prg,
                    startDate,
                    endDate
                } = $location.$$search;

                if (is) {
                    const lowerIs = is.toLowerCase();

                    if (lowerIs.includes('vjhistory')) {
                        return $state.go('vjhistory', {
                            authId,
                            r,
                            startDate,
                            endDate,
                            is,
                            roomId
                        });
                    }

                    if (lowerIs.includes('history') && (r || p)) {
                        return $state.go('invite.old.history', {
                            authId,
                            customerId: r,
                            customerNumber: p,
                            countryCode: c
                        });
                    }

                    if (lowerIs.includes('historySearchByTags'.toLowerCase())) {
                        return $state.go('invite.old.history', {authId, isSearchByTags: true});
                    }

                    if (lowerIs.includes('admin')) {
                        return getRootStore().redirectionService.goToAdmin();
                    }
                }

                return $state.go('invite', {
                    r,
                    p,
                    c,
                    is,
                    roomId,
                    g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    authId,
                    ott,
                    tablet,
                    sw,
                    prg
                });
            }

            window.location.href = response.redirect;
        })
        .catch((err) => {
            // User is not logged in, redirect to login page
            const {
                r,
                p,
                c,
                is,
                roomId,
                g,
                roomCode,
                audio,
                observe,
                emb,
                mini,
                ott,
                tablet,
                sw,
                prg,
                startDate,
                endDate
            } = $location.$$search;

            return PromiseUtilsService.startPromiseWithTimeout(
                () =>
                    trace.error(
                        `App.module failed - ssoCurrentUser:
                            ${JSON.stringify({
                                isMinidashboard: mini,
                                isEmbeddedDashboard: emb
                            })}`,
                        err
                    ),
                3000
            ).finally(() => {
                if (emb) {
                    return $state.go('embeddedDashboardGeneralError');
                }

                return $state.go('login', {
                    p,
                    r,
                    c,
                    is,
                    roomId,
                    g,
                    roomCode,
                    audio,
                    observe,
                    emb,
                    mini,
                    ott,
                    tablet,
                    sw,
                    prg,
                    startDate,
                    endDate
                });
            });
        });
}

function config(
    $stateProvider,
    $urlRouterProvider,
    $compileProvider,
    $httpProvider,
    $translateProvider,
    $locationProvider
) {
    $locationProvider.hashPrefix('');

    // Disable extra debug info
    if (ENV.production) {
        $compileProvider.debugInfoEnabled(false);
    }

    if (!ENV.dev && ALLOW_HTML5_MODE) {
        $locationProvider.html5Mode({enabled: true, requireBase: false});
    }

    // Set default URL entry point if none of the routes match
    // This will run on each unknown route
    $urlRouterProvider.otherwise(($injector, $location, $rootScope) => {
        const $state = $injector.get('$state');
        const db = $injector.get('db');
        const auth = $injector.get('auth');
        const tsUrlUtils = new TsUrlUtilsService($rootScope, $location);
        const observe = tsUrlUtils.getParamValue('observe');
        const encodedExpectedUser = tsUrlUtils.getParamValue('user');
        const expectedUser = encodedExpectedUser && decodeURIComponent(encodedExpectedUser);

        _unknownRouteHandler($state, $location, tsUrlUtils, auth, db, observe, expectedUser);
    });

    $httpProvider.interceptors.push('tokenInterceptor');

    $translateProvider.useSanitizeValueStrategy(null).preferredLanguage('en_US').fallbackLanguage('en_US');

    $translateProvider.useLoader('customTranslateLoader');
}

// eslint-disable-next-line max-params
function afterRun(
    tsLocaleHelper,
    $rootScope,
    tsExceptionHandler,
    $window,
    db,
    auth,
    eventLog,
    tsUrlConfig,
    $localStorage,
    tsExtraLoggingService
) {
    tsLocaleHelper.loadLocale();
    $rootScope.BASE_PATH = BASE_PATH;

    $rootScope.safeApply = (fn) => {
        const phase = $rootScope.$$phase;

        if (phase === '$apply' || phase === '$digest') {
            if (angular.isFunction(fn)) {
                fn();
            }
        } else {
            $rootScope.$apply(fn);
        }
    };

    $rootScope.requireSelectableImage = (imageName, useNewInterface, selected) =>
        $rootScope.requireImage(`${imageName}${useNewInterface && selected ? '-selected' : ''}.png`, useNewInterface);
    $rootScope.requireImage = function (imageFileName, useNewInterface = false) {
        return require(`img/${useNewInterface ? 'new-interface-assets/' : ''}${imageFileName}`); // eslint-disable-line global-require
    };

    $rootScope.includeJs = (filename, elementName, att) =>
        getRootStore().domUtilsService.includeJsFile(filename, elementName, att);
    $rootScope.loadScript = (script) => getRootStore().domUtilsService.loadScript(script);

    if (ENABLE_PINGDOM && PINGDOM_URL) {
        $rootScope.includeJs(PINGDOM_URL, 'body', 'async');
    }

    if ($window.location.origin) {
        $rootScope.windowOrigin = $window.location.origin;
    } else {
        const port = $window.location.port ? ':' + $window.location.port : '';

        $rootScope.windowOrigin = `${$window.location.protocol}//${$window.location.hostname}${port}`;
    }

    tsExceptionHandler.init();

    if (!ENV.production) {
        enableUiRouterDebbuger($rootScope);
    }

    configureDashboardTracing(tsExtraLoggingService, $localStorage, eventLog);

    const safeApply = $rootScope.safeApply;

    reactAppBootstrap({
        angular: {db, auth, tsLocaleHelper, eventLog, safeApply, tsUrlConfig, environmentDetect},
        globals: {window},
        config: {
            socketUrl: tsUrlConfig.get('SOCKET_URL'),
            mobileClientURL: TsUtils.replaceUrlDomain(CLIENT_START_URL, window.location.hostname)
        }
    });
}

async function setEnvironmentDetect() {
    try {
        if (TsEnvironmentDetect.create) {
            environmentDetect = await PromiseUtilsService.startPromiseWithTimeout(TsEnvironmentDetect.create, 5000);

            if (environmentDetect) {
                trace.info('TsEnvironmentDetect.create success');
            }
        }
    } catch (error) {
        trace.error('TsEnvironmentDetect.create error, moving to static TsEnvironmentDetect', error);
    }

    if (!environmentDetect) {
        trace.info('failed using TsEnvironmentDetect.create, moving to static TsEnvironmentDetect');

        environmentDetect = new TsEnvironmentDetect();
    }
}

function bootstrapAngularModule() {
    setEnvironmentDetect().then(() => {
        angular
            .module('app', [
                vendorModule.name,
                componentsModule.name,
                directivesModule.name,
                filtersModule.name,
                servicesModule.name,
                constantsModule.name,
                statesModule.name
            ])
            .config(config)
            .run(afterRun);

        angular.bootstrap(document, ['app'], {
            // Make sure every DI occurrence is properly annotated
            strictDi: true
        });
    });
}

bootstrapAngularModule();

function enableUiRouterDebbuger($rootScope) {
    /* eslint-disable */
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        console.log(
            '$stateChangeStart to ' + toState.name + '- fired when the transition begins. toState,toParams : \n',
            toState,
            toParams,
            fromState
        );
    });
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        console.log('$stateChangeError - fired when an error occurs during transition.');
        console.log(arguments);
    });
    $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        console.log('$stateChangeSuccess to ' + toState.name + '- fired once the state transition is complete.');
    });
    $rootScope.$on('$viewContentLoading', function (event, viewConfig) {
        console.log('$viewContentLoading - view begins loading - dom not rendered', viewConfig);
    });

    /* $rootScope.$on('$viewContentLoaded',function(event){
         // runs on individual scopes, so putting it in "run" doesn't work.
         console.log('$viewContentLoaded - fired after dom rendered',event);
       }); */

    $rootScope.$on('$stateNotFound', function (event, unfoundState, fromState, fromParams) {
        console.log('$stateNotFound ' + unfoundState.to + '  - fired when a state cannot be found by its name.');
        console.log(unfoundState, fromState, fromParams);
    });
    /* eslint-enable */
}

function configureDashboardTracing(tsExtraLoggingService, $localStorage, eventLog) {
    //run localStorage.setItem('ngStorage-TRACE_TO_CONSOLE', true) in DevTools console
    if ($localStorage.TRACE_TO_CONSOLE === true) {
        registerTracerOutput(traceToConsoleOutputFunction('DashboardTrace'));
    }

    registerTracerOutput(tsExtraLoggingService.traceToEventLogOutputFunction);

    enableTracerByName(TECHSEE_MEDIA_TRACER);
    enableTracerByName(DASHBOARD_APP_TRACER);

    tsExtraLoggingService.init(undefined, eventLog);
}
