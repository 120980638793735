import {IDbHistory, IDbRooms, IDbScanner} from './AngularServices';
import {DashboardAppMediaService} from '../../../services/media-service-api/DashboardAppMediaService';
import {KnownMediaStream} from '@techsee/techsee-media-service/lib/MediaConstants';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';
import {EventLogParams, IEventLogsService} from '../EventsLogService';

export type Channel = {channel: number; totalAP: number; freq: number};

export interface IWifiAnalyzerResults {
    _id: string;
    id: string;
    name: string;
    userName?: string;
    description: string;
    customerId?: string;
    deviceModel?: string;
    createdOn: Date;
    accountId: string;
    roomId: string;
    platformType: string;
    active?: boolean;
    wifiConnectionInfo: any;
    scanAreaResults: any[];
    networkDiscoveryDevices: any[];
    networkSpeed: any;
    channelAnalysis: Channel[];
    connectivityGuruData: object;
}

export interface IWifiAnalyzerResultsHistory {
    previews: IWifiAnalyzerResults[];
    totalResults: number;
}

export interface IWifiAnalyzerService {
    runScanner: (
        roomId: string,
        scanId: string,
        onSuccess: (channels: Channel[]) => void,
        onFailure?: () => void
    ) => void;

    getWifiAnalyzerHistory: (
        accountId: string,
        customerId?: string,
        startTime?: Date,
        endTime?: Date,
        pageSize?: number,
        pageNumber?: number
    ) => Promise<IWifiAnalyzerResultsHistory>;

    isWifiAnalyzerExist: (
        accountId: string,
        customerId?: string,
        startTime?: Date,
        endTime?: Date,
        scanId?: string
    ) => Promise<boolean>;
}

export class WifiAnalyzerService implements IWifiAnalyzerService {
    private _mediaService: DashboardAppMediaService | null = null;

    constructor(
        private readonly _scannerService: IDbScanner,
        private readonly _roomsService: IDbRooms,
        private readonly _eventsLogService: IEventLogsService,
        private readonly _db: any
    ) {}

    public setMediaService = (mediaService: DashboardAppMediaService) => {
        this._mediaService = mediaService;
    };

    public runScanner: IWifiAnalyzerService['runScanner'] = async (roomId, scanId, onSuccess, onFailure) => {
        if (!this._mediaService) {
            throw new Error('Media service is not set');
        }

        this._eventsLogService.debug({
            logType: LOG_EVENTS.wifiScanResultScannerRun,
            meta: {scanId, roomId}
        });

        try {
            const result = await this._mediaService.getSnapshotFromKnownStream(KnownMediaStream.USER_VIDEO_STREAM, {
                format: 'image/jpeg'
            });

            this._eventsLogService.debug({
                logType: LOG_EVENTS.wifiScanResultSnapshotTaken,
                meta: {result, scanId, roomId}
            });

            const scanResults = await this._scannerService.scan(result.base64img);
            const response = scanResults.data.text.raw;

            this._eventsLogService.debug({
                logType: LOG_EVENTS.wifiScanResultScannerResponse,
                meta: {response, scanId, roomId}
            });

            await this._roomsService
                .updateWifiScanResult(roomId, {
                    data: {channelAnalysis: this.parseResults(response), scanId}
                })
                .then(() => onSuccess(this.parseResults(response)));
        } catch (error) {
            this._eventsLogService.error({
                logType: LOG_EVENTS.wifiScanResultScannerError,
                meta: {error, scanId, roomId}
            });

            onFailure?.();
        }
    };

    private parseResults(input: string) {
        const result: Record<string, {channel: number; totalAP: number}[]> = {'2.4': [], '5.0': []};

        const regex = /(\d+\.\d+ GHZ)\s*-?\s*\d+ DEVICES(.*?)(?=\d+\.\d+ GHZ|$)/g;
        let match;

        while ((match = regex.exec(input)) !== null) {
            const channelList = [];
            const deviceList = [];
            let channelMatch;
            let deviceMatch;

            const [_, frequency, channels] = match;
            const channelRegex = /channel (\d+)/g;
            const devicesRegex = /(\d+ devices?)/g;

            while ((channelMatch = channelRegex.exec(channels)) !== null) {
                channelList.push(channelMatch[1]);
            }

            while ((deviceMatch = devicesRegex.exec(channels)) !== null) {
                deviceList.push(deviceMatch[1]);
            }

            for (let i = 0; i < channelList.length; i++) {
                if (deviceList[i] && channelList[i]) {
                    const channel = parseInt(channelList[i].split(' ').pop() || '0');
                    const devices = parseInt(deviceList[i].split(' ').shift() || '0');

                    result[frequency.split(' ')[0]].push({channel, totalAP: devices});
                }
            }
        }

        const initialObject: {channel: number; totalAP: number; freq: number}[] = [];
        const flattenedResults = Object.keys(result).reduce((acc, frequency) => {
            result[frequency].forEach(({channel, totalAP}) => {
                acc.push({channel, totalAP, freq: parseFloat(frequency)});
            });

            return acc;
        }, initialObject);

        return flattenedResults;
    }

    public async getWifiAnalyzerHistory(
        accountId: string,
        customerId?: string,
        startTime?: Date,
        endTime?: Date,
        pageSize?: number,
        pageNumber?: number
    ): Promise<IWifiAnalyzerResultsHistory> {
        const history = await this._db.WifiAnalyzer.bulk(accountId, {
            params: {
                customerId,
                startTime,
                endTime,
                pageSize,
                pageNumber
            }
        });

        return history.data;
    }

    public async isWifiAnalyzerExist(
        accountId: string,
        customerId?: string,
        startTime?: Date,
        endTime?: Date,
        scanId?: string
    ): Promise<boolean> {
        const res = await this._db.WifiAnalyzer.isExist(accountId, {
            params: {
                customerId,
                startTime,
                endTime,
                scanId
            }
        });

        return res.data as boolean;
    }
}
