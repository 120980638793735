import {GeneralSettings, VJHistoryFilterSettings} from '../../models/AccountSettings';
import {IInviteFlowManager} from '../../states/invite-new/_contracts/InviteContracts';
import {IRedirectionService} from '../../services/RedirectionService';
import {VJFormController} from '../vj-form/controller';
import {BasicFormModel} from '../common-forms/form-model';
import {getRootStore} from '../../app.bootstrap';
import {IGenericFormController} from '../common-forms/controller';
import {IEventLogsService} from '../../services/EventsLogService';
import {LOG_EVENTS} from '@techsee/techsee-common/lib/constants/event-logs.constants';

export interface IWifiAnalyzerFilterController {
    onVJSessionsSearch(event: any): void;
    searchHistory(): Promise<boolean | void>;
    eventLog(logType: string, meta?: any): Promise<void>;

    formController: IGenericFormController;
}

export class WifiAnalyzerFilterController implements IWifiAnalyzerFilterController {
    readonly formController: IGenericFormController;

    private formModel: BasicFormModel;

    private accountId: string;

    private _redirectionService: IRedirectionService;

    private _eventsLogService: IEventLogsService;

    constructor(
        flowManager: IInviteFlowManager,
        accSettings: VJHistoryFilterSettings,
        accountId: string,
        redirectionService: IRedirectionService,
        accGeneralSettings: GeneralSettings
    ) {
        this.formModel = new BasicFormModel(undefined, true, flowManager.translate);
        this._eventsLogService = getRootStore().eventsLogService;
        this.formController = new VJFormController(
            flowManager.translate,
            accSettings,
            this.formModel,
            this.onVJSessionsSearch.bind(this),
            {
                enablePhoneNumberField: false,
                enableCustomerRefField: true,
                enableEmailField: false,
                enableEndDate: true,
                enableStartDate: true
            },
            accGeneralSettings.reportsDateFormat
        );

        this._redirectionService = redirectionService;
        this.accountId = accountId;
    }

    onVJSessionsSearch(event: any) {
        event && event.preventDefault();

        if (!this.formController || this.formController.isLoading) {
            return;
        }

        const {formStartDateValue, formCustomerId} = this.formController;

        this.formController.setIsLoading(true);

        if (formStartDateValue || formCustomerId) {
            this.eventLog(LOG_EVENTS.wifiScanHistoryResults);
            this.searchHistory()
                .then((dataFound) => {
                    this.formController.setFormError(!dataFound);
                })
                .catch(() => {
                    this.eventLog(LOG_EVENTS.wifiScanHistoryResultsError.type);
                    this.formController.setIsLoading(false);
                })
                .finally(() => {
                    this.formController.setIsLoading(false);
                });
        } else {
            this.formController.setIsLoading(false);
        }
    }

    public eventLog = (logType: string, meta?: any): Promise<void> =>
        this._eventsLogService.info({
            logType,
            accountId: this.accountId,
            meta
        });

    async searchHistory(): Promise<boolean | void> {
        const startDateValue = this.formController.formStartDateValue
            ? new Date(this.formController.formStartDateValue)
            : undefined;
        const endDateValue = this.formController.formEndDateValue
            ? new Date(this.formController.formEndDateValue)
            : new Date();
        const customerId = this.formController.formCustomerId || undefined;

        startDateValue?.setHours(0, 0, 0, 0);
        endDateValue.setHours(23, 59, 59, 999);

        const isWifiAnalyzerExist = await getRootStore().wifiAnalyzerService.isWifiAnalyzerExist(
            this.accountId,
            customerId,
            startDateValue,
            endDateValue
        );
        const {browserUtilsService} = getRootStore();

        if (!isWifiAnalyzerExist) {
            this.formController.setFormError(true);

            return false;
        }

        browserUtilsService.saveToSessionStorage('wifiAnalyzerHistory', {
            accountId: this.accountId,
            startDateValue,
            endDateValue,
            customerId
        });

        this._redirectionService.goToWifiAnalyzerHistory();

        this.formController.setIsLoading(false);

        return true;
    }
}
